import { GreyGooseTheme } from './GreyGooseTheme';
import { PureLightTheme } from './PureLightTheme';
import { PurpleFlowTheme } from './PurpleFlowTheme';
import { DefaultTheme } from './defaultTheme';

export const THEMES = {
  PURE_LIGHT_THEME: 'PureLightTheme',
  GREY_GOOSE_THEME: 'GreyGooseTheme',
  PURPLE_FLOW_THEME: 'PurpleFlowTheme',
  DEFAULT_THEME: 'DefaultTheme',
};

const themeMap = {
  PureLightTheme,
  GreyGooseTheme,
  PurpleFlowTheme,
  DefaultTheme,
};

export function getThemeConfig(theme = THEMES.DEFAULT_THEME) {
  return themeMap[theme];
}
